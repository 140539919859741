
<template>
  <div>
    <div class="text-center my-4">
      <h5>Información de Honorarios Fiduciarios</h5>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-3 form-group">
          <label for="total_pagar" class="form-label">Cantidad a pagar (cuota establecida)</label>
          <input v-model="data.total_pagar" type="text" name="total_pagar" id="total_pagar" class="form-control">
        </div>
        <div class="col-sm-3 form-group">
          <label for="total_pago" class="form-label">Cantidad que pago</label>
          <input v-model="data.total_pago" type="text" name="total_pago" id="total_pago" class="form-control">
        </div>
        <div class="col-sm-2 form-group">
          <label for="no_notaria" class="form-label">Fecha de pago</label>
          <input v-model="data.fecha_pago" type="date" name="fecha_pago" id="fecha_pago" class="form-control">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HonorariosFiduciariosV1"
  ,components: {}
  ,data(){
    return {
      data:{
        total_pagar:null
        ,total_pago:null
        ,fecha_pago:null
      }
    }
  }
  ,mounted() {
  }
  ,computed:{}
  ,methods:{}
}
</script>

<style scoped lang="scss">

</style>
